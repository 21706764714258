const { localStorage } = window;

export const clearStorage = () => {
    localStorage.clear()
}

export const removeStorage = (key) => {
    localStorage.removeItem(key)
}

export const getStorage = (key) => {
    const data = localStorage.getItem(key);
    //console.log(`Getting ${key} from web cache`, data)
    if (data) return JSON.parse(data)
    else return null
}

export const setStorageBook = (book) => {
    console.log('SETTING STORAGE BOOK ', book)
    localStorage.setItem('book', JSON.stringify(book));
}

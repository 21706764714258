import React from 'react';

import { Input, List, Divider } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import {
    Link
} from 'react-router-dom';


class SongList extends React.Component {
    state = {
        loading: false,
        search: '',
        sectionsSearch: [],
    }

    searchHandler = (text) => {
        this.setState({search: text})
        const { categoryId } = this.props.match.params;
        const { sections } = this.props;
        if (categoryId !== 'all') {
            const section = sections.find((s: SectionType) => s.category.id === categoryId)
            if (section) {
                const newSongs = section.songs.filter(song => song.name.toLowerCase().includes(text.toLowerCase())) || [];
                const newSection = [{
                    ...section,
                    songs: newSongs
                }]
                this.setState({sectionsSearch: newSection})
            }
        } else {
            const newSections = sections.map(section => {
                return {
                    ...section,
                    songs: section.songs.filter(song => song.name.toLowerCase().includes(text.toLowerCase())) || []
                }
            })
            this.setState({sectionsSearch: newSections})
        }
    }


    render() {
        const { match, sections } = this.props;
        const { search, sectionsSearch } = this.state;
        const songList = search.length > 0 ? sectionsSearch : this.props.songs;
        const { categoryId } = match.params;
        let songSections = sections;
        if (categoryId !== 'all') {
            songSections = [sections.find((section) => section.category.id === categoryId)];
        }
        if (search.length > 0) {
            console.log('SECTTION SEARCH ', sectionsSearch)
            songSections = sectionsSearch
        }
        return (
            <div className='songlist'>
                <div className='searchbar'>
                    <Input
                        allowClear
                        prefix={<SearchOutlined />}
                        placeholder="Sök"
                        style={{ maxWidth: 300}}
                        onChange={(e) => this.searchHandler(e.target.value)}
                    />
                </div>
                    {songSections.map(section => (
                        <List
                            locale={{emptyText: <div></div>}}
                            className='list'
                            key={section.category.id}
                            header={<Divider style={{margin:0}} orientation='left'>{section.category.name}</Divider>}
                            footer={<div className='m' />}
                            dataSource={section.songs}
                            renderItem={song => (
                                <Link style={{display: 'block'}} to={'/song/'+song.id}>
                                    <List.Item className='song-item'>{song.name.toUpperCase()}</List.Item>
                                </Link>
                            )}
                        />
                    ))}
            </div>
        )
    }
}

export default SongList;

import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCtF29supWdent7BHOwa_J7FKaxujXolc8",
  authDomain: "sangbok-7a52a.firebaseapp.com",
  projectId: "sangbok-7a52a",
  storageBucket: "sangbok-7a52a.appspot.com",
  messagingSenderId: "1037483235850",
  appId: "1:1037483235850:web:165b512f0563ddb819ddf3"
};

firebase.initializeApp(firebaseConfig);

firebase.db = firebase.firestore();

export default firebase;

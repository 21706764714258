import React, { useEffect } from 'react'

import {
    NavLink,
    useLocation
} from 'react-router-dom';

import { Skeleton } from 'antd';

const loadingList = [
    {id: '0'},
    {id: '1'},
    {id: '2'},
    {id: '3'},
    {id: '4'},
    {id: '5'},
    {id: '6'},
    {id: '7'},
    {id: '8'},
    {id: '9'},
    {id: '10'},
]

const Menu = ({categories, close, loading}) => {

    let location = useLocation();

    useEffect(() => {
        close()
    }, [location])

    return (
        <div id="menu">

            {loading ?
                loadingList.map(i => (
                    <Skeleton paragraph={false} key={i.id} active />
                )) :
                <>
                <div className='menu-button' /*style={{paddingTop: 0}}*/>
                    <NavLink to='/songs/all' activeClassName='selected'>Alla sånger</NavLink>
                </div>
                {categories.map(cat => (
                    <div key={cat.id} className="menu-button">
                        <NavLink to={'/songs/' + cat.id} activeClassName='selected'>{cat.name}</NavLink>
                    </div>
                ))}
                </>

            }

            <div className='menu-button menu-footer' style={{marginTop: 40}}>
                <a href="https://isek.se">Till isek.se</a>
            </div>
        </div>
    )
}
export default Menu;

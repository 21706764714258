import React, { useState, useEffect } from 'react'

import { useParams, useHistory} from 'react-router-dom';

import { Divider, PageHeader, message } from 'antd';

const Song = ({songs}) => {
    const [song, setSong] = useState();

    const { id } = useParams();
    const history = useHistory()

    const findSong = async () => {
        const songFound = await songs.find(s => s.id === id);
        if (songFound === undefined) {
            message.error('Kunde inte hitta visan')
            history.goBack()
        } else {
            setSong(songFound)
        }
    }
    useEffect(() => {
        //console.log('USE EFFECT I SONG');
        window.scrollTo(0, 0)
        findSong()
    }, [id])


    const songLoading = {
        name: null,
        trivia: null,
        lyrics: null,
        page: null
    }

    const songLoaded = song ? song : songLoading

    return (
        <div>
            <PageHeader
                onBack={() => history.goBack()}
                title={songLoaded.name}
                //title={<div className='song-title'>{songLoaded.name}</div>}
                style={{paddingLeft: 0, paddingRight: 0}}
            />
            {/* <Divider orientation='left'><h2 className='songTitle'>{songLoaded.name}</h2></Divider> */}
            <div className='song-content'>
                <div className='trivia'>
                    {songLoaded.trivia}
                </div>
                <div className='lyrics-wrapper'>
                    <p className='lyrics'>{songLoaded.lyrics}</p>
                </div>
                {songLoaded.page && <div className='page'>Sida i sångboken: {songLoaded.page}</div>}
            </div>
        </div>
    )
}

export default Song;

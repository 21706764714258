import React, { useState, useEffect } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link
} from 'react-router-dom';

import { Layout, Row, Col, Drawer } from 'antd';
import Menu from './components/Menu'
import SongList from './components/SongList'
import Song from './components/Song'

import useWindowDimensions from './windowSize'

import {
  MenuUnfoldOutlined,
  LoadingOutlined,
} from '@ant-design/icons'

const { Header, Footer, Sider, Content } = Layout;

//const logo = require('/assets/images/isek-logo.svg');


const Main = (props) => {

    const [toggleOpen, openMenu] = useState(false);

    const { height, width } = useWindowDimensions();
    const small = width < 768;
    const mobile = width < 480;
    const { loading, songs, categories, sections } = props;

    // useEffect(() => {
    //     // console.log('USE EFFECT')
    //     // console.log('MOBILE ', mobile)
    //     console.log('TOGGLE OPEN', toggleOpen)
    //     //openMenu(!toggleOpen)
    //     console.log('use effect')
    // }, [toggleOpen])

    useEffect(() => {
        //console.log('TOGGLE OPEN', toggleOpen)
        openMenu(prev => {
            //console.log('PREV ', prev)
            return toggleOpen
        })
    }, [toggleOpen])

    return (
        <Router>
            <Layout>
                <Header id='header'>
                    <Row justify='space-between'>
                        <Col span={4}>
                            {small && (
                                loading ?
                                <LoadingOutlined className='menu-toggle' /> :
                                <MenuUnfoldOutlined onClick={() => openMenu(true)} style={{zIndex: 400}} className='menu-toggle'  />
                            )
                            }
                        </Col>
                        <Col span={16} style={{textAlign: 'center'}}>
                            <Link to='/songs/all' className='main-title'>SÅNGBOK</Link>
                        </Col>
                        <Col span={4} style={{textAlign: 'right'}}>
                            {width > 300  &&
                                <img style={{padding: '0 10px'}} alt='isek' height='54px' width='auto' src='/assets/images/isek-logo.svg' />
                            }
                        </Col>
                    </Row>
                </Header>
                <div style={{height: 64, width: '100%'}} />
                <Layout hasSider={!small}>
                    {small ?
                        <Drawer
                            //title='Kategorier'
                            title={<div style={{fontSize: 18, fontFamily: 'Gotham'}}>Kategorier</div>}
                            placement='left'
                            visible={small && toggleOpen}
                            onClose={() => openMenu(false)}
                            closable={true}
                            footer={
                                <div className='menu-button'>
                                    <a href="https://isek.se">Till isek.se</a>
                                </div>
                            }
                            >
                            <Menu loading={loading} close={() => small && openMenu(false)} categories={categories} />
                        </Drawer> :
                        <Sider
                            id='sider'
                            //breakpoint={'md' || 'xs'}
                            collapsible
                            collapsedWidth={0}
                            //collapsed={small && !toggleOpen}
                            collapsed={small}
                            trigger={null}
                            theme='light'
                            width={mobile ? '100%' : 200}
                            className='sider'>

                            <Menu loading={loading} close={() => small && openMenu(false)} categories={categories} />

                          </Sider>
                    }

                      <Content id="content">
                          {loading ?
                              <div className='loading-content'>
                                  <LoadingOutlined className='loading-big' />
                              </div>
                              :
                              <Routes sections={sections} categories={categories} songs={songs} />
                          }

                          {/* <Switch>
                              <Route path='/songs/:categoryId' render={(props) => <SongList {...props} sections={sections} songs={songs} categories={categories} />}/>
                              <Route path='/song/:id' exact render={(props) => <Song {...props} songs={songs} />} />
                              <Redirect from="/" to="/songs/all" exact />
                              <Redirect from="/songs" to="/songs/all" exact />
                          </Switch> */}
                      </Content>


                </Layout>
                <Footer id='footer'><span style={{whiteSpace: 'nowrap'}}>Sångbok för Sektionen för Industriell ekonomi.</span> <span style={{whiteSpace: 'nowrap'}}>©{new Date().getFullYear()} I-sektionen LTH</span></Footer>
            </Layout>



        </Router>
    )
}

const Routes = ({sections, categories, songs}) => (
    <Switch>
        <Route path='/songs/:categoryId' render={(props) => <SongList {...props} sections={sections} songs={songs} categories={categories} />}/>
        <Route path='/song/:id' exact render={(props) => <Song {...props} songs={songs} />} />
        <Redirect from="/" to="/songs/all" exact />
        <Redirect from="/songs" to="/songs/all" exact />
    </Switch>
)


export default Main;

import React from 'react';
import firebase from './Firebase';

import Main from './Main'

import { getStorage, setStorageBook } from './LocalStorage'

//import { fetchSongs } from './fetchSongs'

// styles
import 'antd/dist/antd.css';
import './App.css';
import './theme/layout.css';
import './theme/list.css';
import './theme/menu.css';
import './theme/song.css';
import './theme/fonts.css';


class App extends React.Component {

    state = {
        loading: true,
        sections: [],
        songs: [],
        categories: []
    }

    async componentDidMount() {
        const timestamp = await this.fetchTimestamp()
        const storage = await getStorage('book');
        if (storage && timestamp) {
            const storageDate = new Date(storage.lastChanged)
            if (timestamp.valueOf() !== storageDate.valueOf()) {
                this.fetchData(timestamp)
            } else {
                console.log('HÄMTAR BOK FRÅN CACHE')
                this.mount(storage.categories, storage.songs, null)
            }
        } else {
            this.fetchData(timestamp);
        }
    }

    fetchTimestamp = () => {
        const dbRef = firebase.db.collection('guilds').doc('isek_lth');
        return new Promise((resolve, reject) => {
            dbRef.get().then(doc => {
                if (!doc.exists) {
                    console.log('NO GUILD FOUND');
                    resolve(null);
                } else {
                    const data = doc.data()
                    if (data && data.lastChanged) {
                        resolve(data.lastChanged.toDate())
                    }
                }
            }).catch(err => {
                console.log('FAILED TO FETCH: ', err)
                resolve(null);
            })
        })

    }

    fetchData = async (newDate) => {
        console.log('HÄMTAR BOK FRÅN DB')
        const dbRef = firebase.db.collection('guilds').doc('isek_lth');
        const categories = await dbRef.collection('categories').get().then(snap => {
            if (snap.empty) {
                console.log('NO CATEGORIES FOUND');
                return []
            } else {
                const catArr = snap.docs.map(doc => {
                    const cat = doc.data();
                    const category = {
                        id: doc.id,
                        name: cat.name
                    }
                    return category
                })
                return catArr
            }
        })
        const songs = await dbRef.collection('songs').get().then(snap => {
            if (snap.empty) {
                console.log('NO SONGS FOUND');
                return [];
            } else {
                const songsArr = snap.docs.map(doc => {
                    const data = doc.data();
                    const song = {
                        id: doc.id,
                        categoryId: data.categoryId,
                        page: data.page,
                        name: data.name,
                        trivia: data.trivia,
                        lyrics: data.lyrics,
                    }
                    return song
                })
                return songsArr;
            }
        })
        this.mount(categories, songs, newDate);
    }

    mount = (categories, songs, newDate) => {
        const sections = [];
        categories.forEach(cat => {
            const songsInCat = songs.filter(s => s.categoryId === cat.id);
            sections.push({category: cat, songs: songsInCat})
        })
        if (newDate) {
            console.log('SETTING LOCAL STORAGE');
            const localStorageItem = {
                categories: categories,
                songs: songs,
                lastChanged: newDate
            }
            setStorageBook(localStorageItem)
        }
        this.setState({sections: sections, categories: categories, songs: songs, loading: false})
    }

    render () {
        const { loading, sections, categories, songs } = this.state;
        return (
            <Main loading={loading} sections={sections} categories={categories} songs={songs} />
        )
    }
}

export default App;
